<template>
  <div class="h-screen flex w-full bg-full-grey">
    <div
      class="
        vx-col
        w-4/5
        sm:w-4/5
        md:w-3/5
        lg:w-3/4
        xl:w-3/5
        mx-auto
        self-center
      "
    >
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div
              class="
                vx-col
                hidden
                sm:hidden
                md:hidden
                lg:block lg:w-1/2
                mx-auto
                self-center
              "
            >
              <img
                src="@/assets/images/pages/forgot-password.png"
                alt="login"
                class="mx-auto main-img"
              />
            </div>
            <div
              class="
                vx-col
                sm:w-full
                md:w-full
                lg:w-1/2
                mx-auto
                self-center
                d-theme-dark-bg
              "
            >
              <div class="px-8 pt-12 pb-12">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Recover your password</h4>
                  <p>
                    Please enter your email address and we'll send you
                    instructions on how to reset your password.
                  </p>
                </div>

                <form @submit.prevent="handleRecoverPassword">
                  <vs-input
                    v-model="email"
                    v-validate="'required|email'"
                    type="email"
                    name="email"
                    label-placeholder="Email"
                    class="w-full"
                  />
                  <v-error :err="errors" field="email" />

                  <div class="mt-8">
                    <vs-button
                      type="border"
                      to="/login"
                      class="px-4 w-full md:w-auto"
                    >
                      Back To Login
                    </vs-button>

                    <vs-button
                      class="
                        float-right
                        px-4
                        w-full
                        md:w-auto
                        mt-3
                        mb-8
                        md:mt-0 md:mb-0
                      "
                      :disabled="!validateForm"
                      @click="handleRecoverPassword"
                    >
                      Recover Password
                    </vs-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { getMessageFromError } from '@/helpers';
import { AuthAction } from '@/store/actionTypes';

import vError from '@/components/VError';

export default {
  components: {
    vError,
  },
  data: () => ({
    email: '',
  }),
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.email !== '' &&
        this.password !== '' &&
        this.password_confirmation !== ''
      );
    },
  },
  methods: {
    ...mapActions('auth', [AuthAction.recoverPassword]),

    async handleRecoverPassword() {
      this.$vs.loading();

      try {
        const { message } = await this.recoverPassword({
          email: this.email,
        });
        this.notifySuccess('Reset link sent', message);

        this.$router.push({ name: 'login' }).catch();
      } catch (error) {
        this.notifyError(error, getMessageFromError(error));
      }

      this.$vs.loading.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-img {
  width: 60%;
}
</style>
